<template>
  <div class="survey-wrapper relative font-poppins">
    <div class="survey-left-panel bg-primary-two shadow-one bg-primary-two">
      <div class="flex flex-col mb-8">
        <h2 class="fs-18 fw-700 text-primary-one">{{ survey.title }}</h2>
        <p class="fs-14 text-secondary-five fw-400">{{ survey.description }}</p>
      </div>
      <div class="flex admin-info text-primary-one fs-14 fw-400">
        <span class="opacity-75">Created By :</span>
        <span class="ml-2">{{ survey.created_by ? getUserName() : '' }}</span>
      </div>
      <div class="flex admin-info text-primary-one fs-14 fw-400">
        <span class="opacity-75">Created on :</span>
        <span class="ml-2">{{ $lodash.format(survey.created_at, 'MMMM dd, yyyy') }}</span>
      </div>
      <div class="flex admin-info text-primary-one fs-14 fw-400">
        <span class="opacity-75">Question :</span>
        <span class="ml-2">{{ totalSteps }}</span>
      </div>
      <div class="current-status flex items-center justify-center">
        <div>
          <div class="current-percentage flex items-center justify-center flex-col mb-5">
            <radial-progress-bar :diameter="225" :strokeWidth="3" :innerStrokeWidth="4" :startColor="'#1C1C1D'" :stopColor="'#1C1C1D'" :innerStrokeColor="'#C9C9C9'"
                                 :completed-steps="response.length"
                                 :total-steps="totalSteps" class="relative survey-radial">
              <p class="percentage a5">{{ (((response.length * 100) / totalSteps)) ? ((response.length * 100) / totalSteps).toFixed(2) : 0 }}%</p>
              <p class="finished">{{ response.length }} / {{ totalSteps }}</p>
            </radial-progress-bar>
          </div>
          <div class="current-time flex justify-center">
            <img class="mr-3" src="/images/icons/survey/clock.svg" alt="">
            <span class="time text-secondary-two">{{ Math.floor(totalSeconds / 60) }}:{{ Math.floor(totalSeconds % 60) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="survey-right-panel relative bg-white shadow-three rounded">
      <div class="flex flex-wrap justify-between pb-5 i-mb-10 bg-primary-three survey-right-box">
        <div class="flex flex-col text-secondary-two fs-14 fw-400">
          <span class="mb-1">Powered by</span>
          <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
        </div>
        <div class="actionBtns flex gap-6 justify-end buttons">
          <button
              v-if="!submitted"
              @click="createSurvey()"
              class="px-4 py-2 focus:outline-none w-full hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three border-primary-four i-border-1 shadow-one rounded flex items-center justify-center">
            Create a Survey
          </button>
          <button
              v-if="!submitted"
              @click="storeSurveyResponse()"
              :class="{'pointer-events-none': !user_email || !user_name}"
              class="px-4 py-2 focus:outline-none w-full hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three border-primary-four i-border-1 shadow-one rounded flex items-center justify-center">
            Submit
          </button>
          <button
              v-if="submitted"
              @click="redoSurvey()"
              class="px-4 py-2 focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three border-primary-four i-border-1 shadow-one w-full rounded flex items-center justify-center">
            Redo Survey
          </button>
        </div>
      </div>
      <div v-if="!submitted" class="user-info survey-right-box i-border-b-1 border-secondary-five">
        <h2 class="fs-16 fw-600 text-primary-one">Personal Information</h2>
        <p class="fs-14 fw-400 text-secondary-two">Please Fill Out your name and Email to help us Get back to You</p>
        <div class="flex flex-wrap inputWrapper gap-5 items-center i-mb-10 mt-5">
          <div class="flex items-center w-full rounded i-border-1 survey-pr border-secondary-five" :class="{'pointer-events-none': getAuthUser}">
            <span class="flex py-4 pl-3 pr-1"><img src="/images/icons/survey/user.svg" alt="icon" class="icon-placeholder"></span>
            <input class="w-full block search-field fs-14 text-primary-one pl-2 bg-transparent" type="text" placeholder="Your name" v-model="user_name">
          </div>
          <div class="flex items-center w-full rounded i-border-1 survey-pr border-secondary-five" :class="{'pointer-events-none': getAuthUser}">
            <span class="flex py-4 pl-3 pr-1"><img src="/images/icons/survey/message.svg" alt="icon" class="icon-placeholder"></span>
            <input class="w-full block search-field fs-14 text-primary-one pl-2 bg-transparent" type="text" placeholder="Your email" v-model="user_email">
          </div>
        </div>
      </div>
      <div v-if="!submitted" class="questions-container survey-right-box">
        <template v-for="(questionComponent, index) in survey.questions">
          <component class="question-item" :is="getComponent(questionComponent.type)"
                     :class="{'mt-5': index > 0, 'slider-gap': lastComponentSlider(index)}"
                     :key="index"
                     :serial="index"
                     :item="questionComponent"
                     :responseMode="responseMode"
                     :ref="'q_' + questionComponent.id"
                     @responseFromChild="responseFromChild"></component>
        </template>
      </div>
      <div class="flex flex-col justify-center items-center thanks-box" v-if="submitted">
        <img src="/images/icons/survey/thanks_star.svg" alt="star" class="thanks-star">
        <h2 class="text-primary-one thanks-text mt-4">Thank you for your Response</h2>
        <p class="text-secondary-two fs-20 mt-4">If you want to create dynamic survey like this, please</p>
        <p class="text-secondary-two fs-20">login to <router-link :to="{name: 'login'}" class="text-primary-four">instrat360.com</router-link> and create beautiful surveys.</p>

        <div class="mt-5">
          <button
              v-if="!submitted"
              @click="createSurvey()"
              class="px-4 py-2 focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three border-primary-four i-border-1 shadow-one w-full rounded flex items-center justify-center">
            Create a Survey
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import RadialProgressBar from 'vue-radial-progress'
import survey from "../../common/survey";
import MultipleChoice from "../../components/survey/question/MultipleChoice";
import Selection from "../../components/survey/question/Selection";
import RatingScale from "../../components/survey/question/RatingScale";
import Rating from "../../components/survey/question/Rating";
import TextType from "../../components/survey/question/TextType";
import OptionInLeft from "../../components/survey/OptionInLeft";
import Dropdown from "../../components/survey/question/Dropdown";
import OrderRanking from "../../components/survey/question/OrderRanking";
import Slider from "../../components/survey/question/Slider";
import DateTime from "../../components/survey/question/DateTime";

export default {
  name: "Survey",
  components: {
    RadialProgressBar, MultipleChoice, Selection, RatingScale, Rating, TextType, DateTime, Slider, OrderRanking, Dropdown, OptionInLeft
  },
  created() {
    const self = this;
    self.surveyCommonData = survey;
    self.$Progress.start();
    if(localStorage.getItem('_token_')) {
      self.$store.dispatch("profile/fetchProfileData");
    }
    self.$store.dispatch("survey/getSingleSurvey", self.$route.params.survey_id).then(response => {
      self.$Progress.finish();
    });
    self.countDownTimer();
  },
  data() {
    return {
      surveyCommonData: null,
      responseMode: true,
      totalSeconds: 0,
      response: [],
      user_name: '',
      user_email: '',
      serverMode: process.env.NODE_ENV,
      submitted: false
    }
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
      profileData: "profile/data",
    }),
    totalSteps() {
      let questions = [];
      if(this.survey && this.survey.questions) {
        questions = this.survey.questions;
      }
      return questions.length;
    },
    getAuthUser() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if(user) {
        this.setUserInfo(user);
        return user;
      }
      return '';
    },
    getRequiredQuestions() {
      return this.survey.questions.filter(item => item.is_required);
    }
  },
  methods: {
    lastComponentSlider(index) {
      if(index > 0) {
        let lastComponent = this.survey.questions[index];
        if(lastComponent && lastComponent.type === 9) {
          return true;
        }
      }
      return false;
    },
    getUserName() {
      let title = '';
      let user = this.survey.created_by;
      if(user && user.first_name) {
        title = user.first_name;
        if(user.last_name) {
          title = title + ' ' + user.last_name;
        }
      } else {
        title = user.email;
      }
      return title;
    },
    setUserInfo(user) {
      this.user_email = user.email;
      this.user_name = user.first_name;
    },
    createSurvey() {
      if(this.profileData && this.profileData.id) {
        self.submitted = true;
        this.$Progress.start();
        this.$store.dispatch("survey/storeSurvey")
            .then(response => {
              if(response && response.data && response.data.status) {
                this.$router.push({name: 'survey.edit', params: {survey_id: response.data.data.id}});
              }
              this.$Progress.finish();
            });
      } else {
        this.$router.push({name: 'login'});
      }
    },
    getComponent(type) {
      let data = this.surveyCommonData.questionType;
      let item = data.find(item => parseInt(item.serial) === parseInt(type));
      if(item) {
        return item.component;
      }
      return '';
    },
    countDownTimer() {
      setTimeout(() => {
        this.totalSeconds += 1
        this.countDownTimer()
      }, 1000);
    },
    responseFromChild(obj) {
      let qResponseIndex = this.response.findIndex(item => parseInt(item.question_id) === parseInt(obj.question_id));
      if(qResponseIndex >= 0) {
        this.response[qResponseIndex] = obj;
      } else {
        this.response.push(obj); // obj: { question_id: 1, answer: {} } -> answer will be json depending on component
      }
    },
    storeSurveyResponse() {
      const self = this;
      if(self.validateData()) {
        self.$Progress.start();
        let data = {
          survey_id: self.$route.params.survey_id,
          response: self.response,
          name: this.user_name,
          email: this.user_email,
          responder_id: this.getAuthUser ? this.getAuthUser.id : ''
        };
        self.$store.dispatch("survey/storeSurveyResponse", data).then(response => {
          if(response && response.data && response.data.status) {
            self.submitted = true;
          }
          self.$Progress.finish();
        });
      }
    },
    validateData() {
      const self = this;
      let error = true;
      let requiredQuestions = self.getRequiredQuestions;
      requiredQuestions.forEach(item => {
        let itemAnswerObj = self.response.find(ansItem => parseInt(ansItem.question_id) === parseInt(item.id) && (ansItem.answer || ansItem.answer === 0));
        if(!itemAnswerObj) {
          error = false;
          this.$refs[`q_${item.id}`][0].hasError = true;
        }
      });
      return error;
    },
    redoSurvey() {
      this.submitted = false;
      this.totalSeconds = 0;
      this.response = [];
    }
  }
}
</script>

<style scoped>
.a5 {
  color: #A5A5A5;
}
.survey-wrapper{
  display: grid;
  grid-template-columns: 355px 1fr;
  gap: 40px;
}
.survey-left-panel {
  padding: 40px 40px 50px;
}
.survey-right-panel {
  margin: 40px 0;
}
.inputWrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.survey-right-box {
  padding: 20px 30px;
}
.buttons {
  width: 325px;
}
.thanks-box {
  height: 900px;
}
.logo-box {
  width: 142px;
  height: 32px;
}
.logo-box span {
  font-size: 8px;
}
.btn-create span {
  font-size: 10px;
  font-weight: 500;
  line-height: 140%;
}
.option-div .option {
  margin-bottom: 20px;
}
.option-div div:last-child {
  margin-bottom: 0px;
}
.admin-info {
  margin-bottom: 24px;
  line-height: 16px;
}
.admin-info .title {
  width: 220px;
}
.current-status {
  height: 425px;
}
.current-percentage {
  width: 220px;
  height: 220px;
  border-radius: 50%;
}
.percentage {
  font-size: 50px;
  line-height: 59px;
}
.finished {
  font-size: 20px;
  line-height: 28px;
}
.time {
  font-size: 30px;
  line-height: 35px;
}
.questions-container {
  height: 750px;
  overflow: scroll;
}
.search-field::placeholder {
  color: #787878;
}
.icon-placeholder {
  width: 12px;
  height: 14px;
}
.survey-pr {
  padding-right: 100px;
}
.thanks-text {
  font-size: 36px;
}
.slider-gap {
  margin-top: 60px;
}
@media all and (max-width: 1100px){
  .survey-wrapper{
    grid-template-columns: 300px 1fr;
  }
}
@media all and (max-width: 1000px){
  .survey-wrapper{
    grid-template-columns: 1fr;
    padding: 40px;
  }
  .survey-left-panel {
    margin: 0;
  }

  .survey-right-panel {
    margin: 0;
  }
}
@media all and (max-width: 650px){
  .survey-right-box{
    gap: 24px;
    justify-content: center;
    padding-top: 40px;
  }
  .actionBtns{
    justify-content: center;
  }
  .inputWrapper{
    display: grid;
    grid-template-columns: 1fr;
  }
  .survey-wrapper{
    padding: 20px;
  }
}
@media all and (max-width: 420px){
  .survey-wrapper{
    padding: 0px;
    gap: 0;
  }
}
</style>
